<template>
  <v-row no-gutters>
    <v-col cols="12" class="hidden-print-only">
      <v-card>
        <v-card-title class="pb-0">Airline Report</v-card-title>
        <v-card-text>
          <v-row dense class="hidden-print-only">
            <v-col cols="12" md="2">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                v-model="fromMenu"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fromDateValue"
                    label="From Date"
                    prepend-icon="event"
                    hide-details
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker :type="datePickerType" v-model="fromDate" @input="fromMenu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                v-model="toMenu"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="toDateValue"
                    label="To Date"
                    prepend-icon="event"
                    hide-details
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker :type="datePickerType" v-model="toDate" @input="toMenu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="10" md="3">
              <v-radio-group v-model="dateType" row hide-details>
                <v-radio label="Day" value="date"></v-radio>
                <v-radio label="Month" value="month"></v-radio>
                <v-radio label="Year" value="year"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="3" md="3">
              <v-autocomplete
                :items="airlineList"
                v-model="airlineId"
                label="Airline"
                item-text="airlineName"
                item-value="airlineId"
                hide-details
              />
            </v-col>
            <v-col cols="2" md="2" class="pt-5">
              <v-btn color="primary" @click="search" fab depressed small>
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn
                :disabled="!showPrint"
                color="primary"
                @click="print"
                class="mx-1"
                depressed
                small
                fab
              >
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn
                :disabled="!showPrint"
                color="primary"
                @click="showChart"
                class="mx-1"
                depressed
                small
                fab
              >
                <v-icon v-if="!isShowCart">bar_chart</v-icon>
                <v-icon v-else>table_chart</v-icon>
              </v-btn>
            </v-col>
            <v-col offset-md="4" cols="4" md="4">
              <!-- <v-card outlined>
                <v-row no-gutters class="px-2">
                  <v-col cols="3">Commission</v-col>
                  <v-col cols="5" class="text-right">{{commissionMMK | defaultPaymentFormat}}</v-col>
                  <v-col
                    cols="4"
                    class="text-right"
                  >{{commissionUSD | defaultPaymentWithDollarSignFormat}}</v-col>
                </v-row>
              </v-card>-->
            </v-col>
            <v-col offset cols="4" md="4">
              <v-card outlined>
                <v-row no-gutters class="px-2">
                  <v-col cols="3">Price</v-col>
                  <v-col cols="5" class="text-right">{{priceMMK | defaultPaymentFormat}}</v-col>
                  <v-col
                    cols="4"
                    class="text-right"
                  >{{priceUSD | defaultPaymentWithDollarSignFormat}}</v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-card v-if="!isShowCart" outlined tile>
            <v-row no-gutters>
              <v-col cols="12">
                <v-simple-table height="65vh" fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th rowspan="2" class="text-center">Airline Name</th>
                        <th rowspan="2" class="text-center">Adult</th>
                        <th rowspan="2" class="text-center">Child</th>
                        <th rowspan="2" class="text-center">Infant</th>
                        <th rowspan="2" class="text-center">
                          Total Seat
                          <v-icon
                            v-if="!isInceaseSortTotalSeat"
                            color="primary"
                            @click="decreandingSortCustomerTotalSeat"
                          >import_export</v-icon>
                          <v-icon
                            v-else
                            color="error"
                            @click="increandingSortCustomerTotalSeat"
                          >import_export</v-icon>
                        </th>
                        <!-- <th colspan="2" class="text-center">Commission</th> -->
                        <th colspan="2" class="text-center">Price</th>
                        <th colspan="2" class="text-center">Action</th>
                      </tr>
                      <tr>
                        <!-- <th class="text-center">MMK</th>
                        <th class="text-center">USD</th>-->
                        <th class="text-center">
                          MMK
                          <v-icon
                            v-if="!isInceaseSortMMK"
                            color="primary"
                            @click="decreandingSortCustomerMMK"
                          >import_export</v-icon>
                          <v-icon
                            v-else
                            color="error"
                            @click="increandingSortCustomerMMK"
                          >import_export</v-icon>
                        </th>
                        <th class="text-center">
                          USD
                          <v-icon
                            v-if="!isInceaseSortUSD"
                            color="primary"
                            @click="decreandingSortCustomerUSD"
                          >import_export</v-icon>
                          <v-icon
                            v-else
                            color="error"
                            @click="increandingSortCustomerUSD"
                          >import_export</v-icon>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in reportData" :key="i">
                        <td class="text-center">{{ item.airName }}</td>
                        <td class="text-right">{{ item.adult }}</td>
                        <td class="text-right">{{ item.child }}</td>
                        <td class="text-right">{{ item.infant }}</td>
                        <td class="text-right">{{ item.totalSeat }}</td>
                        <!-- <td class="text-right">{{ item.mmkTotalCommission | defaultPaymentFormat}}</td>
                        <td
                          class="text-right"
                        >{{ item.usdTotalCommission | defaultPaymentWithDollarSignFormat}}</td>-->
                        <td class="text-right">{{ item.mmkTotal | defaultPaymentFormat }}</td>
                        <td
                          class="text-right"
                        >{{ item.usdTotal | defaultPaymentWithDollarSignFormat }}</td>
                        <td class="text-center" style="width: 50px;">
                          <v-btn
                            @click="getAirlineDetailReport(item)"
                            color="primary"
                            depressed
                            x-small
                            icon
                          >
                            <v-icon>list</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
          <v-card v-else outlined tile>
            <Bar-Chart :chartdata="chartdata" :options="options" />
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="d-print-block d-none">
      <v-row no-gutters v-for="(twenty,i) in reportTwentyData" :key="i">
        <v-col
          cols="12"
        >Airline Report ( from {{fromDateValue | reportDate}} to {{toDateValue | reportDate}} )</v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th rowspan="2" class="text-center">No.</th>
                    <th rowspan="2" class="text-center">Airline Name</th>
                    <th rowspan="2" class="text-center">Adult</th>
                    <th rowspan="2" class="text-center">Child</th>
                    <th rowspan="2" class="text-center">Infant</th>
                    <th rowspan="2" class="text-center">Total Seat</th>
                    <!-- <th colspan="2" class="text-center">Commission</th> -->
                    <th colspan="2" class="text-center">Price</th>
                  </tr>
                  <tr>
                    <!-- <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>-->
                    <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,j) in twenty" :key="j">
                    <td class="text-right" colspan="2" v-if="item.airName == 'Total'">Total</td>
                    <td v-if="item.airName != 'Total'" class="text-center">{{((i)*20)+j+1}}</td>
                    <td v-if="item.airName != 'Total'" class="text-center">{{ item.airName }}</td>
                    <td class="text-right">{{ item.adult }}</td>
                    <td class="text-right">{{ item.child }}</td>
                    <td class="text-right">{{ item.infant }}</td>
                    <td class="text-right">{{ item.totalSeat }}</td>
                    <!-- <td class="text-right">{{ item.mmkTotalCommission | defaultPaymentFormat}}</td>
                    <td
                      class="text-right"
                    >{{ item.usdTotalCommission | defaultPaymentWithDollarSignFormat}}</td>-->
                    <td class="text-right">{{ item.mmkTotal | defaultPaymentFormat }}</td>
                    <td class="text-right">{{ item.usdTotal | defaultPaymentWithDollarSignFormat }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <div class="pagebreak"></div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import reportService from "../service/ReportService";
import airlineService from "../service/AirlineService";
import barChart from "../components/BarChart";
export default {
  data() {
    return {
      isShowCart: false,
      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Total Seat",
            backgroundColor: "#4CAF50",
            data: []
          },
          {
            label: "Price(USD)",
            backgroundColor: "#388E3C",
            data: []
          },
          {
            label: "Price(MMK)",
            backgroundColor: "#FF5252",
            data: []
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false
      },
      adult: 0,
      child: 0,
      infant: 0,
      priceMMK: 0,
      priceUSD: 0,
      airlineId: 0,
      totalSeat: 0,
      commissionMMK: 0,
      commissionUSD: 0,
      toDate: new Date().toISOString().substr(0, 10),
      fromDate: new Date().toISOString().substr(0, 10),
      toMenu: false,
      fromMenu: false,
      showPrint: false,
      isInceaseSortMMK: false,
      isInceaseSortUSD: false,
      isInceaseSortTotalSeat: false,
      reportDetailDialog: false,
      dateType: "date",
      datePickerType: "date",
      reportData: [],
      airlineList: [],
      reportTwentyData: [],
      tableHeader: [
        { text: "Airline Name", value: "airName" },
        { text: "Adult", value: "adult" },
        { text: "Child", value: "child" },
        { text: "Infant", value: "infant" },
        { text: "Total Seat", value: "totalSeat" },
        { text: "Total Commission(MMK)", value: "mmkTotalCommission" },
        { text: "Total Commission(USD)", value: "usdTotalCommission" },
        { text: "Total Price(MMK)", value: "mmkTotal" },
        { text: "Total Price(USD)", value: "usdTotal" }
      ]
    };
  },
  mounted: function() {
    airlineService.getAllAirline().then(data => {
      this.airlineList.push({ airlineId: 0, airlineName: "ALL" });
      this.airlineList.push(...data);
    });
    if (this.$route.query.airlineId != undefined) {
      this.airlineId = Number(this.$route.query.airlineId);
    }
    if (this.$route.query.type != undefined) {
      this.dateType = this.$route.query.type;
    }
    if (this.$route.query.fromDate != undefined) {
      this.fromDateValue = this.formatDate(this.$route.query.fromDate);
    }
    if (this.$route.query.toDate != undefined) {
      this.toDateValue = this.formatDate(this.$route.query.toDate);
    }
    this.search();
  },
  methods: {
    showChart: function() {
      this.isShowCart = !this.isShowCart;
      this.chartdata = {
        labels: [],
        datasets: [
          {
            label: "Total Seat",
            backgroundColor: "#FFC107",
            data: []
          },
          {
            label: "Price(MMK)",
            backgroundColor: "#4CAF50",
            data: []
          },
          {
            label: "Price(USD)",
            backgroundColor: "#FF5252",
            data: []
          }
        ]
      };
      if (this.isShowCart) {
        this.reportData.forEach(report => {
          this.chartdata.labels.push(report.airName);
          this.chartdata.datasets[0].data.push(report.totalSeat);
          this.chartdata.datasets[1].data.push(report.mmkTotal);
          this.chartdata.datasets[2].data.push(report.usdTotal);
        });
      }
    },
    pagePerReport: function(response) {
      this.commissionMMK = 0;
      this.commissionUSD = 0;
      this.adult = 0;
      this.child = 0;
      this.infant = 0;
      this.totalSeat = 0;
      this.priceMMK = 0;
      this.priceUSD = 0;
      var w = 0;
      var start = 0;
      var end = 20;
      this.reportTwentyData.splice(0, this.reportTwentyData.length);
      for (var i = 0; i < response.length; i++) {
        this.commissionMMK += response[i].mmkTotalCommission;
        this.commissionUSD += response[i].usdTotalCommission;
        this.priceMMK += response[i].mmkTotal;
        this.priceUSD += response[i].usdTotal;
        this.priceUSD += response[i].usdTotal;
        this.adult += response[i].adult;
        this.child += response[i].child;
        this.infant += response[i].infant;
        this.totalSeat += response[i].totalSeat;
        if (i % 20 == 0) {
          this.reportTwentyData[w] = response.slice(start, end);
          w++;
          start = end;
          end = end + 20;
        }
      }
      var last = this.reportTwentyData.length - 1;
      this.reportTwentyData[last].push({
        mmkTotalCommission: this.commissionMMK,
        usdTotalCommission: this.commissionUSD,
        mmkTotal: this.priceMMK,
        usdTotal: this.priceUSD,
        totalSeat: this.totalSeat,
        adult: this.adult,
        child: this.child,
        infant: this.infant,
        airName: "Total"
      });
    },
    decreandingSortCustomerMMK: function() {
      var twelve = this.reportData.sort((a, b) =>
        a.mmkTotal > b.mmkTotal ? -1 : 1
      );
      this.pagePerReport(twelve);
      this.isInceaseSortMMK = true;
    },
    increandingSortCustomerMMK: function() {
      var twelve = this.reportData.sort((a, b) =>
        a.mmkTotal > b.mmkTotal ? 1 : -1
      );
      this.pagePerReport(twelve);
      this.isInceaseSortMMK = false;
    },
    decreandingSortCustomerUSD: function() {
      var twelve = this.reportData.sort((a, b) =>
        a.usdTotal > b.usdTotal ? -1 : 1
      );
      this.pagePerReport(twelve);
      this.isInceaseSortUSD = true;
    },
    increandingSortCustomerUSD: function() {
      var twelve = this.reportData.sort((a, b) =>
        a.usdTotal > b.usdTotal ? 1 : -1
      );
      this.pagePerReport(twelve);
      this.isInceaseSortUSD = false;
    },
    decreandingSortCustomerTotalSeat: function() {
      var twelve = this.reportData.sort((a, b) =>
        a.totalSeat > b.totalSeat ? -1 : 1
      );
      this.pagePerReport(twelve);
      this.isInceaseSortTotalSeat = true;
    },
    increandingSortCustomerTotalSeat: function() {
      var twelve = this.reportData.sort((a, b) =>
        a.totalSeat > b.totalSeat ? 1 : -1
      );
      this.pagePerReport(twelve);
      this.isInceaseSortTotalSeat = false;
    },
    getAirlineDetailReport: function(airline) {
      this.$router
        .push({
          name: "airlineDetailReport",
          query: {
            fromDate: this.fromDateValue,
            toDate: this.toDateValue,
            airName: airline.airName,
            airId: airline.airId
          }
        })
        .catch(() => {});
    },
    print: function() {
      window.print();
    },
    search: function() {
      reportService
        .getAirlineReport({
          fromDate: this.formatDateUrl(this.fromDateValue),
          toDate: this.formatDateUrl(this.toDateValue),
          type: this.dateType,
          airlineId: this.airlineId
        })
        .then(response => {
          this.searchHistory(
            this.fromDateValue,
            this.toDateValue,
            this.dateType,
            this.airlineId
          );
          this.reportData.splice(0, this.reportData.length);
          // this.commissionMMK = 0;
          // this.commissionUSD = 0;
          // this.adult = 0;
          // this.child = 0;
          // this.infant = 0;
          // this.totalSeat = 0;
          // this.priceMMK = 0;
          // this.priceUSD = 0;
          if (response.length != 0) {
            this.reportData.push(...response);
            this.pagePerReport(response);
            // var w = 0;
            // var start = 0;
            // var end = 20;
            // this.reportTwentyData.splice(0, this.reportTwentyData.length);
            // for (var i = 0; i < response.length; i++) {
            //   this.commissionMMK += response[i].mmkTotalCommission;
            //   this.commissionUSD += response[i].usdTotalCommission;
            //   this.priceMMK += response[i].mmkTotal;
            //   this.priceUSD += response[i].usdTotal;
            //   this.priceUSD += response[i].usdTotal;
            //   this.adult += response[i].adult;
            //   this.child += response[i].child;
            //   this.infant += response[i].infant;
            //   this.totalSeat += response[i].totalSeat;
            //   if (i % 20 == 0) {
            //     this.reportTwentyData[w] = response.slice(start, end);
            //     w++;
            //     start = end;
            //     end = end + 20;
            //   }
            // }
            // var last = this.reportTwentyData.length - 1;
            // this.reportTwentyData[last].push({
            //   mmkTotalCommission: this.commissionMMK,
            //   usdTotalCommission: this.commissionUSD,
            //   mmkTotal: this.priceMMK,
            //   usdTotal: this.priceUSD,
            //   totalSeat: this.totalSeat,
            //   adult: this.adult,
            //   child: this.child,
            //   infant: this.infant,
            //   airName: "Total"
            // });
            // for (var j = 0; j < this.reportTwentyData; j++) {
            //   var printMMK,
            //     printUSD,
            //     printComMMK,
            //     printComUSD = 0;
            //   for (var k = 0; k < this.reportTwentyData[j].length; k++) {
            //     printComMMK += this.reportTwentyData[j][k].mmkTotalCommission;
            //     printComUSD += this.reportTwentyData[j][k].usdTotalCommission;
            //     printMMK += this.reportTwentyData[j][k].mmkTotal;
            //     printUSD += this.reportTwentyData[j][k].usdTotal;
            //     if (k == 15) {
            //       this.reportTwentyData[j].push({
            //         mmkTotalCommission: printComMMK,
            //         usdTotalCommission: printComUSD,
            //         mmkTotal: printMMK,
            //         usdTotal: printUSD
            //       });
            //     }
            //   }
            // }
            this.showPrint = true;
            this.isShowCart = false;
          }
        });
    },
    searchHistory: function(fromDate, toDate, dateType, airlineId) {
      let query = {
        fromDate: fromDate,
        toDate: toDate,
        type: dateType,
        airlineId: airlineId
      };
      this.$router
        .replace({
          path: "/airlineReport",
          query
        })
        .catch(() => {});
    },
    formatDate: function(date) {
      if (!date) return null;
      var [year, month, day] = date.split("-");
      if (day == undefined) {
        day = "01";
      }
      if (month == undefined) {
        month = "01";
      }
      if (this.dateType == "date") {
        return `${day}-${month}-${year}`;
      } else if (this.dateType == "month") {
        return `${month}-${year}`;
      } else {
        return `${year}`;
      }
    },
    formatDateUrl(date) {
      //if (!date) return null;
      if (this.dateType == "month") {
        const [month, year] = date.split("-");
        return `01-${month}-${year}`;
      } else if (this.dateType == "year") {
        const [year] = date.split("-");
        return `01-01-${year}`;
      }
      return date;
    }
  },
  computed: {
    toDateValue: {
      get: function() {
        var toDatetemp = "";
        toDatetemp = this.formatDate(this.toDate);
        return toDatetemp;
      },
      set: function(value) {
        this.toDate = value;
      }
    },
    fromDateValue: {
      get: function() {
        var fromDatetemp = "";
        fromDatetemp = this.formatDate(this.fromDate);
        return fromDatetemp;
      },
      set: function(value) {
        this.fromDate = value;
      }
    }
  },
  watch: {
    dateType: function() {
      if (this.dateType != "year") {
        this.datePickerType = this.dateType;
      } else {
        this.datePickerType = "month";
      }
    }
  },
  components: {
    "Bar-Chart": barChart
  }
};
</script>
<style scoped>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #ffffff;
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.12) !important;
}
.theme--light.v-data-table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
tr td {
  padding: 0px 5px 0px 5px !important;
  height: 25px !important;
  font-size: 12px;
}
tr th {
  font-size: 12px;
  padding: 0px 2px 0px 2px !important;
  font-weight: 900;
}
</style>
